import React from 'react';
import { Routes, Route } from "react-router-dom"
// import "./assets/css/main.css";
import Dashboard from './pages/Dashboard';
import ChatView from './pages/ChatView';
import ContentManagement from './pages/ContentManagement';
import UserQueries from './pages/UserQueries';
import Login from './pages/Login';
import ProtectedRoute from './lib/privateRoute';

function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Login/> } />
        {/* <Route path="/dashboard" element={ <Dashboard/> } /> */}
        <Route path="/dashboard" element={ <ProtectedRoute> <Dashboard />  </ProtectedRoute> }  />
        <Route path="/chatbot/:name" element={ <ProtectedRoute> <ChatView />  </ProtectedRoute> } />
        <Route path="/content-management" element={ <ProtectedRoute> <ContentManagement />  </ProtectedRoute> } />
        <Route path="/user-queries" element={ <ProtectedRoute> <UserQueries />  </ProtectedRoute> } />
      </Routes>
    </div>
  );
}

export default App;
