import React, { useState, useRef, useEffect } from 'react';
import { DatePicker } from 'antd';
import dateImg from '../assets/images/date.svg';
import { SwapOutlined, CloseOutlined } from '@ant-design/icons';

const FilterByDate = ({ onDateRangeChange }) => {
  const [selectedOption, setSelectedOption] = useState('Any Date');
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [dates, setDates] = useState([null, null]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    if (option === 'Custom Date') {
      setShowCustomDatePicker(true);
    } else {
      console.log("Sachin")
      setShowCustomDatePicker(false);
      setDates([null, null]); // Clear date selection when option is not "Custom Date"
      onDateRangeChange(null, null); // Reset date range in parent component
    }
  };

  const onCloseDatePicker = () => {
    setShowCustomDatePicker(false);
    if(dates){
      console.log(dates)
    setSelectedOption('Custom Date');
    }else{
      setSelectedOption('Any Date');
    }
  };

 const handleDateChange = (dateRange) => {
  if (dateRange && dateRange[0] && dateRange[1]) {
    const startDate = dateRange[0].toDate(); // Convert to native JS Date object
    const endDate = dateRange[1].toDate();   // Convert to native JS Date object

    setDates(dateRange);
    console.log('Selected Date Range:', startDate, endDate);
    onDateRangeChange(startDate, endDate); // Pass to parent function
  } else {
    // This handles clearing/reset scenario where dateRange may be null or incomplete
    setDates(undefined); // Reset dates
    console.log('Date range cleared.');
    onDateRangeChange(null, null); // Clear date range in parent component
  }
};


  const toggleDropdown = () => {
    if (!showCustomDatePicker) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };


  useEffect(() => {
    if(!dates){
      setShowCustomDatePicker(false)
      setSelectedOption('Any Date')
    }
   
  }, [dates]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }}>
      {!showCustomDatePicker && (
        <div className="dropdown sort-btn">
          <button
            className="custom-dropdown-toggle"
            onClick={toggleDropdown}
            style={{
              padding: '9px 35px 9px 15px',
              border: '1px solid #DEDEDE',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              background: 'none',
              cursor: 'pointer',
            }}
          >
            <img
              src={dateImg}
              alt="date"
              style={{ width: '20px', marginRight: '10px' }}
            />
            {selectedOption}
          </button>

          {isDropdownOpen && (
            <ul
              className="dropdown-menu dropdown-menu-end"
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                right: '0',
                padding: '10px',
                border: '1px solid #DEDEDE',
                borderRadius: '10px',
                backgroundColor: '#fff',
                zIndex: 10,
                maxHeight: '200px',
                overflowY: 'auto',
                display: 'block',
              }}
            >
              {/* <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleOptionChange('Any Date')}
                >
                  Any Date
                </a>
              </li> */}
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleOptionChange('Custom Date')}
                >
                  Custom Date
                </a>
              </li>
            </ul>
          )}
        </div>
      )}

      {showCustomDatePicker && (
        <div className="date-filter-box">
          <DatePicker.RangePicker
            value={dates}
            onChange={handleDateChange}
            format="DD MMM YYYY"
            separator={<SwapOutlined />}
            style={{ width: '100%' }}
          />
          <CloseOutlined
            style={{
              position: 'absolute',
              right: '-30px',
              top: '10px',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#888',
            }}
            onClick={onCloseDatePicker}
          />
        </div>
      )}
    </div>
  );
};

export default FilterByDate;
