import React, { useState ,useEffect} from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import ProfileImgChat from '../assets/images/profile-img-chat.jpg';
import DcLogo from '../assets/images/dcube-ai-logo_black.png';
import ProductList from '../assets/images/product-list.png';
import ServicesList from '../assets/images/services-list.png';
import CheckMark from '../assets/images/check-mark.png';
import BackArrow from '../assets/images/left-arrow.png';
import { useParams, useLocation ,useNavigate } from 'react-router-dom';
import { get } from '../lib/HttpServices'; 
import { toast } from 'react-toastify';


const ChatView = () => {
    // const { name } = useParams();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [chatData, setchatData] = useState([]);
    const [serviceData, setserviceData] = useState([]);
    const [brochureData, setbrochureData] = useState([]);
    const toggleSidebar = () => {
      setIsSidebarOpen(prevState => !prevState);
    };
    
    const location = useLocation();
    const navigate = useNavigate();
    // const { lead_id, date, location: customerLocation } = location.state || {};
    const { lead_id,name, date, location: customerLocation } = location.state || {}; 

    const dummy_chatData = [
        {
          "user": "Hi",
          "chatbot": "Hello and welcome to Dcube Ai! I'm DCUBE AI's chatbot. Would you like to introduce yourself to us"
        },
        {
          "user": "Yes",
          "chatbot": "Great! Please fill in your details to get started."
        },
        {
          "user": "No", 
          "chatbot": "How may I assist you today?"
        },
        {
          "user": "Contact Information",
          "chatbot": "How can I help you with contact information?"
        }
      ]
      

      const handleBackButtonClick = () => {
        // Navigate to the ChatView component
        window.history.go(-1);
      }
    useEffect(() => {
        // If necessary state is missing, redirect back or show an error
        if (!location.state) {
          navigate('/dashboard', { replace: true }); // Redirect to an error page or a default page

        }
      }, [location.state, navigate]);

      useEffect(() => {
        // If necessary state is missing, redirect back or show an error
        if (location.state) {
          get(`get_chat_history?phone_number=${lead_id}`)
          .then((res) => {
          setchatData(res.data.data)
          })
          .catch((error) => {
            if (error.response) {
            console.log("errr ");
            }
          });
        }

        get(`get_services?phone_number=${lead_id}`)
        .then((res) => {
        setserviceData(res.data.data)
        })
        .catch((error) => {
          if (error.response) {
          console.log("errr ");
          }
        });
        get(`get_brochures?phone_number=${lead_id}`)
        .then((res) => {
        setbrochureData(res.data.data)
        })
        .catch((error) => {
          if (error.response) {
          console.log("errr ");
          }
        });

      }, []);

    return (
        <div>
            <Sidebar isSidebarOpen={isSidebarOpen} />
                <section className="home-section">
                    <Header toggleSidebar={toggleSidebar} />
                        <div className="project-task chat-box">
                            <div className="row">
                                <div className="col-lg-12">
                                    
                                </div>
                                <div className="col-lg-8">
                                    <div className="inside-box">
                                    <div className="w-100 d-flex align-items-center justify-content-between">
                                      
                                        <button
                                          style={{
                                            background: '#eaeefa57',
                                            padding: '14px',
                                            borderRadius: '50px',
                                            cursor: 'pointer',
                                            border: 'none', // Add this to remove the default button border
                                            outline: 'none'  // Optional: removes the outline when focused
                                          }}
                                          onClick={handleBackButtonClick}
                                        >
                                          <img src={BackArrow} alt="Back" style={{ width: '27px' }} />
                                        </button>

                                        <div className="text-end">
                                          <h2>{name}</h2>
                                          <h6>{customerLocation}, {date}</h6>
                                        </div>
                                    </div>
                                    <hr />
                                    {/*  */}

                                    
                                        <div className="">
                                  <div className="chat-container">
                                      <div className="chat-content">
                                      {chatData.length > 0 ? (
                                            chatData.map((chat, index) => (
                                              <React.Fragment key={index}>
                                                <div className="response-side mb-3">
                                                  <div className="pic-chat-bot-user">
                                                    <p>{name.charAt(0)}</p>
                                                  </div>
                                                  <div className="box-chat chat-msg">
                                                    <p>{chat.user}</p>
                                                  </div>
                                                </div>
                                                <div className="chat-bot-msg mb-3">
                                                  <div className="box-chat chat-right-box">
                                                    <p>{chat.chatbot}</p>
                                                  </div>
                                                  <div className="pic-chat-bot">
                                                    <img src={DcLogo} alt="profile" className="img-fluid" />
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            ))
                                          ) : (
                                            <div className="no-data-container">
                                            <p>There are no chat to display</p>
                                          </div>// Render this message when chatData is empty
                                          )}
                                      </div>
                                    </div>

                                           
                                            {/*  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="inside-box">
                                    <div>
                                      {/* <h3>
                                        Downloads
                                      </h3> */}
                                      <p style={{color: '#a8a7a7', fontStyle: 'italic'}}>User Downloaded List</p>
                                    </div>
                                    {/* <div> 
                                      <div className="d-flex align-items-center">
                                        <img src={ProductList} alt="product List" style={{width:'23px'}} />
                                        <h5 className="ms-2 mb-0">Products</h5>
                                      </div>
                                      
                                      <ul className="download-list">
                                          <li>
                                            <span> 
                                               Product 1
                                            </span>
                                          </li>
                                          <li>
                                          <span> 
                                          Product 2
                                          </span> 
                                          </li>
                                      </ul>
                                    </div> */}
                                     <div> 
                            <div className="d-flex align-items-center">
                              <img src={ProductList} alt="product List" style={{width: '23px'}} />
                              <h5 className="ms-2 mb-0">Products</h5>
                            </div>
                            
                            <ul className="download-list">
                            {brochureData.length > 0 ? (
                                <ul className="download-list">
                                  {brochureData.map((item, index) => (
                                    <li key={index}>
                                      <span>{item}</span>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No data available</p>
                              )}
                            </ul>
                          </div>

                                    <div> 
                                    <div className="d-flex align-items-center">
                                        <img src={ServicesList} alt="product List" style={{width:'23px'}} />
                                         <h5 className="ms-2 mb-0">Services</h5>
                                      </div>
                                      <ul className="download-list">
                                      {serviceData.length > 0 ? (
                                <ul className="download-list">
                                  {serviceData.map((item, index) => (
                                    <li key={index}>
                                      <span>{item}</span>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No data available</p>
                              )}
                                      </ul>

                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
            </section>
        </div>
    )
}

export default ChatView;
